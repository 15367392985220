<template>


<div class="relative z-[100]" role="dialog" aria-modal="true" v-if="showModal">
  <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
  <div class="fixed inset-0 hidden bg-gray-500 bg-opacity-75 transition-opacity md:block"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div class="flex min-h-full items-stretch justify-center text-center md:items-center md:px-2 lg:px-4">
      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          To: "opacity-100 translate-y-0 md:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 md:scale-100"
          To: "opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
      -->
      <div class="flex w-full transform text-left text-base transition md:my-8 md:max-w-5xl md:px-4 lg:max-w-5xl">
        <div class="relative flex w-full items-center overflow-hidden bg-white px-4 pb-8 pt-14 shadow-2xl sm:px-6 sm:pt-8 md:p-6 lg:p-8">
          <button type="button" class="absolute right-4 top-4 text-gray-400 hover:text-gray-500 sm:right-6 sm:top-8 md:right-6 md:top-6 lg:right-8 lg:top-8" @click="showModal = false ">
            <span class="sr-only">Close</span>
            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <div class="grid w-full grid-cols-1 items-start gap-x-6 gap-y-8 sm:grid-cols-12 lg:gap-x-8">
            <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-100 sm:col-span-4 lg:col-span-5">
                <SliderComponent :images="selectedProduct.images"/>
            </div>
            <div class="sm:col-span-8 lg:col-span-7 p-6">
              <h2 class="text-2xl font-bold text-gray-900 sm:pr-12">{{selectedProduct.title}}</h2>

              <section aria-labelledby="information-heading" class="mt-2">
                <h3 id="information-heading" class="sr-only">Product information</h3>

                <p class="text-md text-stone-700">Starting at <span class="text-stone-900">€{{selectedProduct.startingAt}}</span></p>
                <p class="text-md text-stone-700">Estimated production time  <span class="text-stone-900">{{selectedProduct.estimatedProductionTimeInDays}} days</span></p>
              </section>
              <hr class="mt-6 mb-6" />
              <section aria-labelledby="options-heading" class="mt-10">
                <h3 id="options-heading" class="sr-only">Product options</h3>

                <label for="hs-select-label" class="block text-sm font-medium mb-2 dark:text-white">Decoration</label>
                   <select id="hs-select-label" class="py-3 px-4 pr-9 block w-full border border-1 border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 border-gray-200" v-model="selectedOption">
                     <option v-for="o in selectedProduct.options" :key="o.id" :value="o">{{o.title}}</option>

                   </select>
                   <br />
                   <label for="hs-select-label" class="block text-sm font-medium mb-2 dark:text-white">Quantity</label>
                     <input type="number" class="py-3 px-4 block w-full border border-gray-200 rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400" min="25" v-model="selectedQuantity">
                     <br />

                     <div v-if="getPricing() != '0.00'">
                         <label for="hs-select-label" class="block text-sm font-medium mb-2 dark:text-white">Price</label>
                         <p class="text-xl text-stone-700"><span class="text-stone-900">€{{selectedPPU}} / unit</span></p>
                         <p class="text-lg text-stone-700"><span class="text-stone-600">€{{getPricing()}}</span></p>
                     </div>
                     <div v-else>
                          <label for="hs-select-label" class="block text-sm font-medium text-red mb-2 dark:text-white">Please select a quantity higher than {{selectedOption.pricing[0].fromQuantity}}</label>
                      </div>


              </section>

              <section aria-labelledby="options-heading" class="mt-10" v-for="o in selectedProduct.options" :key="o.id" style="display: none;">
                  <h3>{{o.title}}</h3>


                  <p class="text-s,m text-stone-700" v-for="pair in o.pricing" :key="pair.fromQuantity">{{pair.fromQuantity}} units - <span class="text-stone-900">€{{pair.pricePerUnit}}/unit</span></p>

                </section>
                <hr class="mt-6 mb-6" />
                <section aria-labelledby="information-heading" class="mt-2">
                   <label for="hs-select-label" class="block text-sm font-medium mb-2 dark:text-white">Description</label>

                    <p class="text-md text-stone-900">{{selectedProduct.description}}</p>
                  </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  <!-- ========== MAIN CONTENT ========== -->
  <!-- Sidebar Toggle -->
  <!--<div class="sticky top-0 inset-x-0 z-0 bg-white border-y px-4 sm:px-6 md:px-8 lg:hidden dark:bg-gray-800 dark:border-gray-700">
    <div class="flex items-center py-4">
      <button type="button" class="text-gray-500 hover:text-gray-600" data-hs-overlay="#application-sidebar" aria-controls="application-sidebar" aria-label="Toggle navigation">
        <span class="sr-only">Toggle Navigation</span>
        <svg class="w-5 h-5" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
        </svg>
      </button>
      <ol class="ml-3 flex items-center whitespace-nowrap min-w-0" aria-label="Breadcrumb">
        <li class="text-sm font-semibold text-gray-800 truncate dark:text-gray-400" aria-current="page">
          Dashboard
        </li>
      </ol>
    </div>
  </div>-->
  <!-- End Sidebar Toggle -->

  <!-- Sidebar -->
  <div id="application-sidebar" class="hs-overlay hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform hidden fixed top-0 left-0 bottom-0 z-[60] w-72 bg-white border-r border-gray-200 pt-7 pb-10 overflow-y-auto scrollbar-y lg:block lg:translate-x-0 lg:right-auto lg:bottom-0 dark:scrollbar-y dark:bg-gray-800 dark:border-gray-700">
    <div class="px-6">
      <a class="flex-none text-xl font-semibold dark:text-white" href="#" aria-label="Brand"><img class="w-28" src="/img/swag-black.png"/></a>
    </div>
    <nav class="hs-accordion-group p-6 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
        <span class="mb-0 mt-2 py-2 px-2.5 font-semibold text-stone-900">Categories</span>
      <ul class="space-y-1.5 mt-2">


        <li v-for="c in categories" :key="c.id">
          <a class="flex items-center gap-x-3.5 py-2 px-2.5 text-md text-stone-700 rounded-md hover:bg-[#FFEFE3] dark:bg-gray-900 dark:text-white" :href="'#' + c.title">
            {{c.title}}
          </a>
        </li>

      </ul>

      <div class="mt-6  rounded bg-gray-50 p-6 text-center leading-2.5"><p class="mb-2"><strong class="block text-normal mb-2 text-md">Didn't find what you are looking for?</strong> We are able to source any item you'd like. Just reach out to one of our swag experts!</p>

      <a href="https://tally.so/r/3q5yz7"><button type="button" class="py-3 px-6 inline-flex justify-center items-center gap-2 mt-2 rounded-md border border-transparent font-medium bg-[#FFA776] text-white hover:bg-[#FEE1CA] focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 transition-all text-sm dark:focus:ring-offset-gray-800">
        Contact swag expert
      </button></a>

      </div>
    </nav>

  </div>
  <!-- End Sidebar -->

  <!-- Content -->
<div class="w-full pt-10 px-4 sm:px-6 md:px-8 lg:pl-72">


      <div class="mx-auto max-w-full px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8" v-for="c in categories" :key="c.id" :id="c.title">
          <h2 class="text-2xl font-bold tracking-tight text-gray-900">{{c.title}}</h2>
          <p>{{c.description}}</p>

          <div class="mt-6 grid grid-cols-2 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-5 xl:gap-x-8">
                <div class="group relative" v-for="p in c.products" :key="p.id" @click="showDetails(p)">
                  <div class=" w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75">
                    <img :src="p.image_url" alt="" class="h-full w-full object-cover object-center lg:h-full lg:w-full aspect-square ">
                  </div>
                  <div class="mt-4 flex justify-between">
                    <div>
                      <h3 class="text-normal text-stone-800 font-medium">
                          {{p.title}}
                        <a>
                          <span aria-hidden="true" class="absolute inset-0"></span>
                        </a>
                      </h3>
                      <p class="mt-1 text-sm text-gray-500">Starting at <span class="text-gray-700">€{{p.startingAt}}</span></p>
                      <p class="mt-1 text-sm text-gray-500">Estimated production time: <span class="text-gray-700">{{p.estimatedProductionTimeInDays}} days</span></p>
                    </div>
                    <p class="text-sm font-medium text-gray-900"></p>
                  </div>
                </div>

              <!-- More products... -->
            </div>
        </div>
    </div>
  <!-- End Content -->
  <!-- ========== END MAIN CONTENT ========== -->
</template>

<script>
import axios from 'axios';
import SliderComponent from '@/components/SliderComponent.vue';
export default {
  name: 'HomeView',
  components: {
      SliderComponent
  },
  data() {
      return {
        products: [],
        categories: [],

        selectedProduct: null,
        showModal: false,

        selectedQuantity: 100,
        selectedOption: null,
        selectedPPU: 0,
      }
    },
    methods: {
        showDetails: function(p) {

            this.selectedProduct = p;
            let options = p.options;
            if (options.length > 0) {
                this.selectedOption = options[0];
            }
            this.showModal = true;
        },
        getPricing: function() {

            var ppu = 0;
                this.selectedOption.pricing.forEach((p)=> {
                    if(this.selectedQuantity >= p.fromQuantity) {
                        ppu = p.pricePerUnit;
                    }
                });

                this.selectedPPU = ppu;
                return (this.selectedQuantity * ppu).toFixed(2);
        }
    },
    computed () {
    },
  mounted () {
      var self = this;
        axios
            .get('https://api.swagsum.com/catalog')
            .then(function (response) {
                self.categories = response.data;
          })

        console.log(this.categories);
    }
}
</script>
